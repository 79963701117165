#goToErrorsButton {
  color: var(--bs-white);
  background-color: var(--bs-danger);
  border-color: var(--bs-danger);
}

#goToErrorsButton:disabled {
  color: var(--bs-white);
  background-color: var(--bs-success);
  border-color: var(--bs-success);
}

.outline-danger {
  outline: var(--bs-danger) solid 2px !important;
  box-shadow: 0 !important;
}

.outline-danger:focus  {
  outline: var(--bs-danger) solid 2px !important;
  box-shadow: 0 !important;
}

.outline-danger:active  {
  outline: var(--bs-danger) solid 2px !important;
  box-shadow: 0 !important;
}